<template>
  <div id="services">
    <v-row class="align-end">
      <v-col cols="12" sm="12" class="pa-0">
        <h2 class="main-color font-bold service-title mt-4">خدمات الصالون</h2>
        <p class="error--text text-center mt-4" v-if="validationMsg">
          {{ validationMsg }}
        </p>
        <v-expansion-panels class="service-accordion">
          <v-expansion-panel v-for="(cat, index) in allCats" :key="index">
            <v-expansion-panel-header>
              {{ cat.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-4">
              <div v-for="(service, i) in cat.services" :key="i">
                <v-checkbox
                  v-model="selectedServices"
                  :disabled="canEditSelect(service.id)"
                  :label="service.name"
                  :value="service.id"
                ></v-checkbox>

                <p v-if="canEditSelect(service.id)" class="hint-text">
                  لا يمكنك حذف هذه الخدمة حيث تم استخدامها من قبل الصالون الخاص
                  بك
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="12" class="text-left pa-0 mt-4">
        <v-btn
          color="#a53860"
          class="next-btn white--text"
          @click.prevent="submitServicesForm()"
          large
          >التالى</v-btn
        >
        <v-btn
          color="#a53860"
          class="next-btn white--text"
          @click.prevent="previousTab()"
          large
          >السابق</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "servicesForm",
  props: ["allCats", "salonSelectedServices"],
  data() {
    return {
      selectedServices: [],
      validationMsg: "",
      selectedCatWithSessions: [],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  watch: {
    selectedServices: {
      handler() {
        if (this.selectedServices.length > 0) {
          this.validationMsg = "";
        }
      },
    },
  },
  methods: {
    canEditSelect(serviceId) {
      let found = this.salonSelectedServices.some(
        (service) => service.id == serviceId && service.can_delete == false
      );
      found = found ? true : false;
      return found;
    },
    checkEventType($event) {
      console.log($event);
    },

    submitServicesForm() {
      if (this.selectedServices.length > 0) {
        let servicesObjs = this.salonSelectedServices;
        // check selected id not in salonSelectedServices delete it from salon Objs
        servicesObjs.forEach((service, index) => {
          if (!this.selectedServices.includes(service.id)) {
            servicesObjs.splice(index, 1);
          }
        });

        this.selectedServices.forEach((serviceId) => {
          let found = servicesObjs.some((service) => service.id == serviceId);
          if (!found) {
            this.allCats.forEach((cat) => {
              if (cat.services.find((service) => serviceId == service.id)) {
                servicesObjs.push(
                  cat.services.find((service) => serviceId == service.id)
                );
              }
            });
          }
        });

        this.$emit("submitServicesForm", servicesObjs);
      } else {
        this.validationMsg = "يجب على الاقل اختيار خدمة واحدة";
      }
    },
    previousTab() {
      this.$emit("previousTab", 0);
    },
  },
  mounted() {
    console.log("mounted");
    this.salonSelectedServices.forEach((service) => {
      this.selectedServices.push(service.id);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

#services {
  .service-title {
    font-size: 2rem !important;
  }

  .service-accordion {
    margin-top: 30px !important;

    .hint-text {
      font-size: 12px !important;
    }
  }

  .next-btn {
    width: 80px;
    font-weight: bold;
    margin: 0 5px;
  }
}
</style>
