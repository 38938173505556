<template>
  <div>
    <pageHeader />

    <div class="basicInfo">
      <v-container>
        <v-row>
          <v-col>
            <div class="head">
              <h2 class="main-title mb-3">البيانات الاساسية</h2>
              <p class="d-flex align-center">
                <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="3" class="d-none d-sm-none d-md-block"></v-col>
          <v-col cols="12" sm="12" md="9" class="pa-sm-0 fields mt-5">
            <v-row>
              <v-col cols="12" sm="8" class="pa-sm-0">
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="#a53860"></v-tabs-slider>

                  <v-tab class="mr-0"> البيانات الاساسية </v-tab>
                  <v-tab :disabled="tab < 1"> الخدمات </v-tab>
                  <v-tab :disabled="tab < 2"> الجلسات</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <basicInfoForm
                          v-if="tab == 0 && dataLoaded"
                          :readyBasicInfoData="basicInfoData"
                          @submitBasicInfo="submitBasicInfo"
                          @checkImages="checkImages"
                        ></basicInfoForm>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <servicesForm
                          v-if="tab == 1"
                          :allCats="allCats"
                          :salonSelectedServices="allSalonInfo.services"
                          @submitServicesForm="submitServicesForm"
                          @previousTab="previousTab"
                          @selectedCatWithServices="getSelectedCatWithServices"
                        ></servicesForm>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <Sessions
                          v-if="tab == 2"
                          @previousTab="previousTab"
                          @openAddNewSession="openAddNewSession"
                          @editSession="editSession"
                          @deleteSession="openDeleteSession"
                          @submitAllData="submitAllData"
                          :selectedServices="allSalonInfo.services"
                          :validation_errors="validation_errors"
                        ></Sessions>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" sm="4" v-if="tab == 0">
                <div class="salon-images">
                  <h3 class="main-color font-bold">صور الصالون</h3>
                  <div class="logo-div">
                    <p class="error--text text-center" v-if="logoValidationMsg">
                      {{ logoValidationMsg }}
                    </p>
                    <!-- ============== logo =================== -->
                    <div
                      v-if="
                        allSalonInfo.logo_show && allSalonInfo.logo_show.url
                      "
                    >
                      <img
                        :src="allSalonInfo.logo_show.url"
                        id="logo"
                        alt=""
                        width="100px"
                      />
                      <v-btn icon @click="openlogo" class="edit">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn large icon @click="openlogo">
                        <v-icon class="cam-icon" color="#a53860" large
                          >mdi-camera-plus</v-icon
                        >
                      </v-btn>
                    </div>
                    <input
                      type="file"
                      style="display: none"
                      id="logo_image"
                      @change="onAddLogoFile"
                      accept="image/*"
                    />
                    <p class="second-color">صورة اللوجو</p>
                  </div>
                  <div class="basicimg-div">
                    <input
                      type="file"
                      style="display: none"
                      id="basic_image"
                      @change="onAddBasicFile"
                      accept="image/*"
                    />
                    <!-- ============== basic image =================== -->
                    <p
                      class="error--text text-center"
                      v-if="basicValidationMsg"
                    >
                      {{ basicValidationMsg }}
                    </p>
                    <div
                      v-if="
                        allSalonInfo.main_image_show &&
                        allSalonInfo.main_image_show.url
                      "
                    >
                      <img
                        :src="allSalonInfo.main_image_show.url"
                        id="basic"
                        alt=""
                        width="100px"
                      />
                      <v-btn icon @click="openBasicImage" class="edit">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                    <v-btn v-else large icon @click="openBasicImage">
                      <v-icon class="cam-icon" color="#a53860" large
                        >mdi-camera-plus</v-icon
                      >
                    </v-btn>
                    <p class="second-color">الصورة الاساسية</p>
                  </div>

                  <div class="other-div">
                    <p
                      class="error--text text-center"
                      v-if="otherValidationMsg"
                    >
                      {{ otherValidationMsg }}
                    </p>
                    <div class="other-images">
                      <!-- ============== other images =================== -->
                      <div
                        v-for="(img, index) in allSalonInfo.other_images_show"
                        :key="index"
                        class="other-image"
                      >
                        <img
                          id="otehr_image"
                          :src="img.url"
                          alt=""
                          width="100px"
                        />
                        <v-btn
                          class="delete-btn"
                          @click="openDeleteImage(index, img.id)"
                          icon
                          small
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </div>
                      <v-btn icon class="cam-icon" @click="openOtherImages">
                        <v-icon color="#a53860" large>mdi-camera-plus</v-icon>
                      </v-btn>
                    </div>
                    <input
                      type="file"
                      style="display: none"
                      id="other_images"
                      @change="onAddOtherFiles"
                      accept="image/*"
                      multiple
                    />

                    <p class="second-color mt-5">صور أخرى</p>
                  </div>
                </div>
              </v-col>

              <!--========================= add new sessions ============================== -->
              <v-col
                cols="12"
                sm="4"
                v-if="tab == 2 && showSessionCard == true"
              >
                <div class="pa-md-5 addNewSession">
                  <!-- <h3 class="main-color font-bold">العناية بالبشرة</h3> -->
                  <p
                    class="error--text text-center"
                    v-if="sessionExistValidationMsg"
                  >
                    {{ sessionExistValidationMsg }}
                  </p>
                  <v-form class="basicInfoForm" v-model="valid" ref="form">
                    <v-row class="mt-4">
                      <v-col cols="12" class="py-0">
                        <label for="sessionName" class="main-color"
                          >إسم الجلسة</label
                        >
                        <v-text-field
                          class="pt-0 mt-0"
                          v-model="sessionObj.name"
                          :rules="[validationRules.required]"
                          name="sessionName"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <label for="price" class="main-color">السعر</label>
                        <v-text-field
                          class="pt-0 mt-0"
                          v-model="sessionObj.price"
                          :rules="[
                            validationRules.required,
                            validationRules.numeric,
                          ]"
                          name="price"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <label for="sessionName" class="main-color"
                          >مدة الجلسة</label
                        >
                        <v-row class="mt-4">
                          <v-col cols="6" class="">
                            <v-text-field
                              class="pt-0 mt-0"
                              v-model="sessionObj.hrs"
                              :rules="[
                                validationRules.required,
                                validationRules.numeric,
                                checkHrLimit(sessionObj.hrs),
                                checkHrsMins(sessionObj.hrs, sessionObj.mins),
                              ]"
                              name="hr"
                              label="ساعه"
                              type="number"
                              min="0"
                              max="24"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="">
                            <v-text-field
                              class="pt-0 mt-0"
                              v-model="sessionObj.mins"
                              :rules="[
                                validationRules.required,
                                validationRules.numeric,
                                checkMinsLimit(sessionObj.mins),
                                checkHrsMins(sessionObj.hrs, sessionObj.mins),
                              ]"
                              name="hr"
                              label="دقيقه"
                              type="number"
                              min="0"
                              max="59"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="py-0">
                        <label for="sessionName" class="main-color"
                          >متاح بالمنزل</label
                        >
                        <v-radio-group
                          v-model="sessionObj.available_at_home"
                          :rules="[validationRules.required]"
                          row
                        >
                          <v-radio label="نعم" value="true"></v-radio>
                          <v-radio label="لا" value="false"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <hr class="main-hr my-2" />
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <div class="d-flex align-baseline justify-center">
                          <p class="mx-2">مخفى</p>
                          <v-switch
                            v-model="sessionObj.active"
                            color="#a53860"
                            inset
                          ></v-switch>
                          <p class="mx-2">مفعل</p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-center">
                        <v-btn
                          color="#a53860"
                          class="next-btn white--text mx-2"
                          @click.prevent="addEditSession()"
                          large
                          >حفظ</v-btn
                        >

                        <v-btn
                          color="#a53860"
                          class="next-btn white--text mx-2"
                          @click.prevent="cancelSession()"
                          large
                          >الغاء</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-col>

              <!-- ======================= delete dialog ==================== -->
              <v-dialog v-model="deleteDialog" width="30%">
                <v-card id="contact-dialog">
                  <v-btn icon @click="deleteDialog = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  <v-card-text class="text-center pa-4 pa-sm-4">
                    <p class="second-color message-send">{{ text }}</p>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="deleteType == 'image'"
                      color="#a53860"
                      class="white--text"
                      @click.prevent="deleteImage"
                      >نعم</v-btn
                    >
                    <v-btn
                      v-if="deleteType == 'session'"
                      color="#a53860"
                      class="white--text"
                      @click.prevent="deleteSession"
                      >نعم</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import pageHeader from "@/components/pageHeader.vue";
import basicInfoForm from "@/components/salon/basicInfoForm.vue";
import servicesForm from "@/components/salon/servicesForm.vue";
import Sessions from "@/components/salon/sessions.vue";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "salonBasicInfo",
  components: { pageHeader, basicInfoForm, servicesForm, Sessions },
  mixins: [validationMixin],

  data() {
    return {
      validation_errors: "",
      text: "هل تريد حذف الصوره",
      deleteType: "image",
      selectedImageIndex: "",
      deleteDialog: false,
      valid: false,
      tab: 0,
      allCats: [],
      logoValidationMsg: "",
      basicValidationMsg: "",
      otherValidationMsg: "",
      sessionExistValidationMsg: "",
      allSalonInfo: {},
      basicInfoData: { salonName: "", tax_number: "", home_service_fees: "" },
      dataLoaded: false,
      sessionObj: {
        name: "",
        price: "",
        hrs: "",
        mins: "",
        active: true,
        can_delete: true,
        available_at_home: "",
      },

      showSessionCard: false,
      // for sessions tab
      selectedServiceIndex: "",
      selectedSessionIndex: "",
      saveType: "new",
      deletedImgId: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    checkHrLimit(hrs) {
      if (hrs > 12) {
        return "يجب انا تكون عدد الساعات اقل من او يساوى 12";
      } else {
        return true;
      }
    },
    checkMinsLimit(mins) {
      if (mins > 59) {
        return "يجب انا تكون عدد الدقائق اقل من او يساوى 59";
      } else {
        return true;
      }
    },
    checkHrsMins(hrs, mins) {
      if (hrs <= 0 && mins <= 0) {
        return "يجب ان تكون الساعات او الدقائق اكبر من صفر";
      }
      return true;
    },
    cancelSession() {
      this.sessionObj = {
        name: "",
        price: "",
        hrs: "",
        mins: "",
        active: true,
        available_at_home: "",
      };
      this.$refs.form.resetValidation();
      this.showSessionCard = false;
      this.sessionExistValidationMsg = "";
    },
    openAddNewSession(serviceIndex) {
      this.showSessionCard = true;
      this.saveType = "new";
      this.selectedServiceIndex = serviceIndex;
    },

    editSession(serviceIndex, sessionIndex, session) {
      this.selectedServiceIndex = serviceIndex;
      this.selectedSessionIndex = sessionIndex;
      this.sessionObj = { ...session };
      this.saveType = "edit";
      this.showSessionCard = true;
    },
    openDeleteSession(serviceIndex, sessionIndex) {
      this.selectedServiceIndex = serviceIndex;
      this.selectedSessionIndex = sessionIndex;
      this.text = "هل تريد حذف الجلسة";
      this.deleteType = "session";
      this.deleteDialog = true;
    },
    deleteSession() {
      this.allSalonInfo.services[this.selectedServiceIndex].sessions.splice(
        this.selectedSessionIndex,
        1
      );
      this.text = "";
      this.deleteType = "";
      this.deleteDialog = false;
    },
    getSelectedCatWithServices(items) {
      this.selectedCatWithServices = items;
    },
    openDeleteImage(index, id) {
      this.deleteDialog = true;
      this.deleteType = "image";
      this.text = "هل تريد حذف الصوره";
      this.selectedImageIndex = index;
      this.deletedImgId = id;
    },
    deleteImage() {
      this.$http
        .get(this.base_url + "/salon/media/" + this.deletedImgId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.allSalonInfo.other_images_show.splice(
              this.selectedImageIndex,
              1
            );
            this.deleteDialog = false;
            this.deleteType = "";
            this.text = "";
            this.deletedImgId = "";
          }
        });
    },
    checkSessionNameUnique(sessions, name) {
      return sessions.some((session) => session.name == name);
    },
    // save session on add - edit
    addEditSession() {
      this.sessionExistValidationMsg = "";
      let nameExist = false;
      if (this.saveType == "new") {
        nameExist = this.checkSessionNameUnique(
          this.allSalonInfo.services[this.selectedServiceIndex].sessions,
          this.sessionObj.name
        );
      }

      if (!nameExist) {
        if (this.valid) {
          if (this.saveType == "new") {
            this.allSalonInfo.services[this.selectedServiceIndex].sessions.push(
              this.sessionObj
            );
          } else {
            //  = this.sessionObj;
            console.log(this.selectedServiceIndex, this.selectedServiceIndex);
            this.$set(
              this.allSalonInfo.services[this.selectedServiceIndex].sessions,
              this.selectedSessionIndex,
              this.sessionObj
            );
          }

          this.sessionObj = {
            name: "",
            price: "",
            hrs: "",
            mins: "",
            active: true,
            available_at_home: "",
          };

          this.$refs.form.resetValidation();
          this.showSessionCard = false;
          this.selectedServiceIndex = "";
          this.selectedSessionIndex = "";
        } else {
          this.validate();
        }
      } else {
        this.sessionExistValidationMsg = "لايمكن تكرار اسم الجلسة لنفس الخدمه";
      }
    },
    previousTab(tab) {
      this.tab = tab;
    },

    openlogo() {
      document.getElementById("logo_image").click();
    },
    openBasicImage() {
      document.getElementById("basic_image").click();
    },
    openOtherImages() {
      document.getElementById("other_images").click();
    },
    onAddLogoFile(e) {
      this.logoValidationMsg = "";
      const selectedImage = e.target.files[0];
      if (selectedImage.type.includes("image/")) {
        this.createBase64Image(selectedImage, "single", "logo");
      } else {
        this.logoValidationMsg = "يسمح بالصور فقط";
      }
    },
    onAddBasicFile(e) {
      this.basicValidationMsg = "";
      const selectedImage = e.target.files[0];
      if (selectedImage.type.includes("image/")) {
        this.createBase64Image(selectedImage, "single", "basic");
      } else {
        this.basicValidationMsg = "يسمح بالصور فقط";
      }
    },
    onAddOtherFiles(e) {
      this.otherValidationMsg = "";
      for (let index = 0; index < e.target.files.length; index++) {
        let selectedImage = e.target.files[index];
        if (selectedImage.type.includes("image/")) {
          this.createBase64Image(selectedImage, "multi", "other");
        } else {
          this.otherValidationMsg = "يسمح بالصور فقط";
        }
      }
    },
    createBase64Image(fileObject, type, name) {
      const render = new FileReader();

      render.onload = (e) => {
        if (type == "single") {
          if (name == "logo") {
            if (
              this.allSalonInfo.logo_show &&
              this.allSalonInfo.logo_show.url
            ) {
              this.allSalonInfo.logo_show.url = e.target.result;
              this.allSalonInfo.logo = e.target.result;
            } else {
              let logoObj = { id: "", url: "" };
              logoObj.url = e.target.result;
              this.allSalonInfo.logo_show = logoObj;
              this.allSalonInfo.logo = e.target.result;
            }
          } else {
            if (
              this.allSalonInfo.main_image_show &&
              this.allSalonInfo.main_image_show.url
            ) {
              this.allSalonInfo.main_image_show.url = e.target.result;
              this.allSalonInfo.main_image = e.target.result;
            } else {
              let mainImageObj = { id: "", url: "" };
              mainImageObj.url = e.target.result;
              this.allSalonInfo.main_image_show = mainImageObj;
              this.allSalonInfo.main_image = e.target.result;
            }
          }
        } else {
          this.allSalonInfo.other_images.push(e.target.result);
          this.allSalonInfo.other_images_show.push({
            id: "",
            url: e.target.result,
          });
        }
      };
      render.readAsDataURL(fileObject);
    },
    getServices() {
      this.$http
        .get(this.base_url + "/front/getCategoriesWithServices", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.allCats = response.data.data;
          }
        });
    },
    getSalonInfo() {
      this.$http
        .get(this.base_url + "/front/getSalonBasiceInfoWeb", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.allSalonInfo = response.data.data;
            this.basicInfoData.salonName = this.allSalonInfo.name
              ? this.allSalonInfo.name
              : "";
            this.basicInfoData.tax_number = this.allSalonInfo.tax_number
              ? this.allSalonInfo.tax_number
              : "";
            this.basicInfoData.home_service_fees = this.allSalonInfo
              .home_service_fees
              ? this.allSalonInfo.home_service_fees
              : "";
            this.dataLoaded = true;
          }
        });
    },
    checkImages() {
      if (!this.allSalonInfo.logo_show.url) {
        this.logoValidationMsg = "يجب ادخال صورة لوجو";
      }
      if (!this.allSalonInfo.main_image_show.url) {
        this.basicValidationMsg = "يجب ادخال صورة اساسية";
      }
    },
    submitBasicInfo(basicInfo) {
      this.allSalonInfo.name = basicInfo.salonName;
      this.allSalonInfo.tax_number = basicInfo.tax_number;
      this.allSalonInfo.home_service_fees = basicInfo.home_service_fees;
      if (this.allSalonInfo.logo_show && this.allSalonInfo.main_image_show) {
        this.tab = 1;
      } else {
        if (
          !this.allSalonInfo.logo &&
          !this.allSalonInfo.logo_show &&
          !this.allSalonInfo.logo_show.url
        ) {
          this.logoValidationMsg = "يجب ادخال صورة لوجو";
        } else if (
          !this.allSalonInfo.main_image &&
          !this.allSalonInfo.main_image_show &&
          !this.allSalonInfo.main_image_show.url
        ) {
          this.basicValidationMsg = "يجب ادخال صورة اساسية";
        }
      }
    },
    submitServicesForm(selectedServices) {
      this.allSalonInfo.services = selectedServices;
      this.tab = 2;
    },
    submitAllData() {
      this.validation_errors = "";
      this.$http
        .post(this.base_url + "/salon/updateBasecInfo", this.allSalonInfo, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error) {
            this.validation_errors = response.data.status.message;
          } else {
            console.log("success");
            localStorage.setItem(
              "profile_image",
              this.allSalonInfo.logo_show.url
            );
            window.location.href = "/salonSettings?tab=1";
          }
        });
    },
  },
  mounted() {
    this.getServices();
    this.getSalonInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
label {
  font-size: 1.2rem !important;
}
.basicInfo {
  margin: 50px 0;
  .head {
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }
}

.salon-images {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: center;
  border-radius: 0 0 0 60px;

  .logo-div {
    margin-top: 25px;
    position: relative;

    img {
      width: 65px;
      height: 65px;
      border: 1px solid $second-color;
      border-radius: 50%;
    }
    .cam-icon {
      font-size: 60px !important;
    }
    .edit {
      position: absolute;
      bottom: 16px;
      right: 32%;
    }
  }

  .basicimg-div {
    position: relative;
    margin-top: 25px;
    img {
      width: 65px;
      height: 65px;
      border: 1px solid $second-color;
      border-radius: 50%;
    }
    .cam-icon {
      font-size: 60px !important;
    }
    .edit {
      position: absolute;
      bottom: 16px;
      right: 32%;
    }
  }

  .other-div {
    // margin-top: 25px;
    .other-images {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .other-image {
        position: relative;
        margin-top: 30px;

        .delete-btn {
          position: absolute;
          right: 10px;
          bottom: -19px;
        }
        img {
          width: 40px;
          height: 40px;
          margin: 0 3px;
          border: 1px solid $second-color;
          border-radius: 50%;
        }
      }
      .cam-icon {
        margin: 0 3px;
        font-size: 40px;
        margin: 0 3px;
        margin-top: 30px;
      }
    }
  }
}

.addNewSession {
  background-color: #f7f7f7;
  border-radius: 0 0 0 60px;
}
</style>
