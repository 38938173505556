<template>
  <div id="sessions">
    <v-row class="align-end">
      <v-col cols="12" sm="12" class="pa-0">
        <p class="error--text text-center" v-if="validationMsg">
          {{ validationMsg }}
        </p>
        <p class="error--text text-center my-3" v-if="validation_errors">
          {{ validation_errors }}
        </p>
        <div
          class="cats my-4"
          v-for="(service, serviceIndex) in selectedServices"
          :key="serviceIndex"
        >
          <h2 class="main-color font-bold cat-title mt-4">
            {{ service.name }}
          </h2>
          <div
            class="d-flex justify-space-between services my-4"
            v-for="(session, sessionIndex) in service.sessions"
            :key="sessionIndex"
          >
            <p class="session-title">{{ session.name }}</p>
            <div>
              <!-- @click="editSession(serviceIndex, sessionIndex)" -->
              <v-btn
                icon
                @click="editSession(serviceIndex, sessionIndex, session)"
              >
                <v-icon color="#a53860">mdi-pencil</v-icon>
              </v-btn>
              <!--" -->
              <v-btn
                icon
                @click="deleteSession(serviceIndex, sessionIndex)"
                v-if="session.can_delete == true"
              >
                <v-icon color="#a53860">mdi-close</v-icon>
              </v-btn>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon class="mx-1"
                    ><v-icon color="#CBCBCB"
                      >mdi-close-circle-outline</v-icon
                    ></v-btn
                  >
                </template>
                <span
                  >لا يمكنك حذف هذه الجلسة لأنه تم استخدامها في الحجوزات
                  السابقة</span
                >
              </v-tooltip>
            </div>
          </div>

          <div class="addSession text-left">
            <p class="second-color font-bold">
              <v-btn icon @click="openAddNewSession(serviceIndex)">
                <v-icon color="#a53860">mdi-plus</v-icon>
              </v-btn>
              اضافة جلسة
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="text-left pa-0">
        <v-btn
          color="#a53860"
          class="next-btn white--text"
          @click.prevent="submitAllData()"
          large
          :loading="loading"
          >حفظ</v-btn
        >
        <v-btn
          color="#a53860"
          class="next-btn white--text"
          @click.prevent="previousTab()"
          large
          >السابق</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Sessions",
  props: ["selectedServices", "validation_errors"],
  data() {
    return {
      loading: false,
      validationMsg: "",
    };
  },
  watch: {
    validation_errors: {
      handler() {
        if (this.validation_errors) {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    openAddNewSession(serviceIndex) {
      this.$emit("openAddNewSession", serviceIndex);
    },
    editSession(serviceIndex, sessionIndex, session) {
      this.$emit("editSession", serviceIndex, sessionIndex, session);
    },
    deleteSession(serviceIndex, sessionIndex) {
      // delete service from selectedCatWithServices
      this.$emit("deleteSession", serviceIndex, sessionIndex);
    },
    submitAllData() {
      this.validationMsg = "";
      this.selectedServices.forEach((service) => {
        if (service.sessions.length == 0) {
          this.validationMsg = "يجب ادخال جلسه على الاقل لكل خدمة";
        }
      });
      if (!this.validationMsg) {
        this.loading = true;
        this.$emit("submitAllData");
      }
    },
    previousTab() {
      this.$emit("previousTab", 1);
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

#sessions {
  .cat-title {
    font-size: 30px !important;
  }
  .services {
    border-bottom: 1px solid #ccc;
    .session-title {
      font-size: 1.2rem !important;
      margin-bottom: 0 !important;
    }
  }
  .next-btn {
    width: 80px;
    font-weight: bold;
    margin: 0 5px;
  }
}
</style>
