<template>
  <div id="basicInfoForm">
    <v-form class="basicInfoForm" v-model="valid" ref="form">
      <v-row class="align-end">
        <v-col cols="12" sm="6" class="pa-0">
          <label for="salonName">اسم الصالون</label>
          <v-text-field
            v-model="basicInfoData.salonName"
            :rules="[validationRules.required, validationRules.spaces]"
            class="pt-0"
            label=""
            name="salonName"
          ></v-text-field>
          <label for="taxNumber">الرقم الضريبى</label>
          <v-text-field
            v-model="basicInfoData.tax_number"
            :rules="[validationRules.required, validationRules.spaces]"
            class="pt-0"
            label=""
            name="taxNumber"
          ></v-text-field>
          <label for="home_service_fees">رسوم الخدمة بالمنزل</label>
          <v-text-field
            v-model="basicInfoData.home_service_fees"
            :rules="[
              validationRules.required,
              validationRules.spaces,
              validationRules.numeric,
            ]"
            min="0"
            class="pt-0"
            label=""
            name="home_service_fees"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="text-left pa-0">
          <v-btn
            color="#a53860"
            class="next-btn white--text"
            @click.prevent="submitBasicInfo()"
            large
            >التالى</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "basicInfoForm",
  mixins: [validationMixin],
  props: ["readyBasicInfoData"],
  data() {
    return {
      valid: false,
      basicInfoData: {
        salonName: "",
        tax_number: "",
        home_service_fees: "",
      },
    };
  },
  methods: {
    submitBasicInfo() {
      if (this.valid) {
        this.$emit("submitBasicInfo", this.basicInfoData);
      } else {
        this.validate();
        this.$emit("checkImages");
      }
    },
  },
  mounted() {
    this.basicInfoData = this.readyBasicInfoData;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";
.basicInfoForm {
  margin-top: 25px;
  label {
    font-size: 1rem !important;
    color: $main-color;
  }

  .next-btn {
    width: 80px;
    font-weight: bold;
    margin: 0 5px;
  }
}
</style>
